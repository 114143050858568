<template>
  <b-row>
    <b-col lg="12">
      <p class="text-left" v-if="courseData.length === 0">This student is not enrolled in any courses</p>
    </b-col>

    <b-col cols="12" lg="6" md="6" sm="12" v-for="(course, index) in courseData" :key="course.id">
      <router-link :to="{ name: 'apps-roster-course-view', params: { id: course.id } }">
        <b-card no-body class="border border-dark">
          <b-card-body class="d-flex w-100">
            <div class="d-flex flex-column truncate w-100 text-left">
              <span class="d-flex border-bottom mb-1 pb-1">
                <b-avatar :text="avatarText(course.name)" :variant="`light-primary`" size="45px" class="mr-1"></b-avatar>

                <h5 class="font-weight-normal">
                  {{ course.name }}
                </h5>
              </span>
              <span class="d-flex justify-content-between">
                <span class="float-left">
                  <b-badge :variant="resolveCourseStatusVariant(course.status)">
                    {{ course.status }}
                  </b-badge>
                </span>

                <span v-if="firstClass(course)" class="float-right text-dark">
                  {{ moment(firstClass(course).date).utc().format('MMMM Do YYYY') }}
                </span>
                <span class="float-right" v-else> No Class Scheduled </span>
              </span>
              
            </div>
          </b-card-body>
        </b-card>
      </router-link>
    </b-col>

    <b-col cols="12" md="12" class="d-flex align-items-end justify-content-end w-100" v-if="courseData.length > 10">
      <b-pagination
        v-model="coursesPage"
        :per-page="coursesPerPage"
        :total-rows="coursesTotal"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="float-right mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BCardBody, BAvatar, BRow, BCol, BBadge, BPagination } from 'bootstrap-vue';
import { avatarText, title } from '@core/utils/filter';
import useStudentsList from '@/views/apps/student/students-list/useStudentsList';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import router from '@/router';
import moment from 'moment';

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BPagination,
  },
  props: {
    studentId: {
      type: String,
      required: true,
    },
  },
  methods: {
    firstClass(course) {
      let classes = course.classes;

      if (!classes) {
        return undefined;
      }

      let sorted = classes.filter((a) => a && a.date).sort((a, b) => a.date - b.date);

      return sorted[0];
    },
  },
  setup(props) {
    const courseData = ref({});
    const coursesPage = ref(1);
    const coursesPerPage = ref(10);
    const coursesTotal = ref(1);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-student/fetchStudentCourses', {
        id: props.studentId,
        queryParams: {
          limit: coursesPerPage.value,
          page: coursesPage.value,
          populate: 'classes',
          sortBy: '_id:desc',
        },
      })
      .then((response) => {
        courseData.value = response.data.results;
        coursesTotal.value = response.data.totalResults;
        console.log(`course`, response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 404) {
          courseData.value = undefined;
        }
      });

    const resolveCourseStatusVariant = (status) => {
      if (status === 'created') return 'secondary';
      if (status === 'enrolling') return 'primary';
      if (status === 'waiting') return 'warning';
      if (status === 'live') return 'info';
      if (status === 'completed') return 'success';
      return 'primary';
    };

    return {
      courseData,
      avatarText,
      title,
      moment,
      resolveCourseStatusVariant,

      coursesPage,
      coursesPerPage,
      coursesTotal,
    };
  },
};
</script>
