<template>
  <div>
    <b-row>
      <p class="text-center w-100" v-if="mondayComments.length === 0">No monday comments created for this student, yet.</p>
      <app-timeline v-else class="w-100 mt-0 m-2">
        <app-timeline-item
          class="w-100"
          v-for="(comment, index) in mondayComments"
          :key="index"
          :title="comment.author"
          :subtitle="comment.comment"
          :time="`${comment.createdAt !== comment.updatedAt ? ('Last Updated @ ' + comment.updatedAt + ' — ') : ``}${comment.createdAt}`"
          variant="success"
        />
      </app-timeline>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BTab,
  BTabs,
  BCol,
  BLink,
  BBadge,
  BRow,
  BCardText,
  BModal,
  VBTooltipPlugin,
} from "bootstrap-vue";
import moment from 'moment';
import StudentEditTabSocialLinks from "@/views/apps/student/students-edit/tabs/StudentEditTabSocialLinks.vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'StudentSocialItem',
  components: {
    StudentEditTabSocialLinks,
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BTab,
    BTabs,
    BCol,
    BLink,
    BBadge,
    BRow,
    BModal,
    BCardText,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    'b-tooltip': VBTooltipPlugin,
  },
  props: {
    studentData: {
      type: Object,
    },
    profileData: {
      type: Object,
    },
  },
  mounted() {
    this.loadComments();
  },
  data() {
    return { mondayComments: [] };
  },
  methods: {
    async loadComments() {

      const promise = store.dispatch('app-student/fetchStudentProfileMondayNotes', {
        id: this.studentData.id,
        queryParams: { populate: 'studentId userId', limit: 50, sortBy: '-createdAt' }
      });

      return promise
        .then((response) => {
          const { results } = response.data;

          this.mondayComments = results.map(comment => {
            return {
              author: comment.userId.name,
              comment: comment.textBody,
              createdAt: moment(comment.createdAt).format('L hh:mm A '),
              updatedAt: moment(comment.updatedAt).format('L hh:mm A '),
            }
          })

          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching comment list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    }
  },
  setup(props) {
    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);
    }

    return {
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
